import { ztnaPrismaAccessRoutes } from '@pats/ztna-react-app/manifest'
import { definition as icon } from './faSlidersHSquare'
import { i18n } from './i18n'
import { isZtnaAvail } from '../env/env'
import type { SparkyFramework } from '@sparky/framework/build/types/framework'
import { ZTNA_APP_KEY } from '@pats/ztna-react-app'

export default function manifest({
	vars,
	sparkyFramework
}: {
	vars: Record<string, any>
	sparkyFramework: SparkyFramework
}) {
	// return ({ auth, main, onTsgUnload }: any) => {
	return (props: any) => {
		const intl = i18n(navigator.language ?? 'en')
		return {
			key: 'settings',
			title: intl.formatMessage({ defaultMessage: 'Settings' }),
			path: '/settings',
			expandable: true,
			noRBACheck: true,
			exact: false,
			icon,
			mergeNav: true,
			children: [
				{
					key: ZTNA_APP_KEY,
					title: intl.formatMessage({ defaultMessage: 'ZTNA Connector' }),
					contentClassName: 'panwds-tw3',
					expandable: true,
					path: ztnaPrismaAccessRoutes.baseUrl,
					exact: false,
					noRBACheck: true,
					avail: isZtnaAvail,
					// @TODO: remove this once the new nav code from sparky is available
					callAvail: true,
					children: [
						{
							key: 'overview',
							title: intl.formatMessage({ defaultMessage: 'Overview' }),
							path: ztnaPrismaAccessRoutes.overview
						},
						{
							key: 'connector-groups',
							title: intl.formatMessage({ defaultMessage: 'Connector Groups' }),
							path: ztnaPrismaAccessRoutes.connectorGroups
						},
						{
							key: 'connectors',
							title: intl.formatMessage({ defaultMessage: 'Connectors' }),
							path: ztnaPrismaAccessRoutes.connectors
						},
						{
							key: 'app-targets',
							title: intl.formatMessage({ defaultMessage: 'Application Targets' }),
							path: ztnaPrismaAccessRoutes.applications
						}
					],
					component: async () => import('../main').then(m => m.activate())
				}
			]
		}
	}
}
