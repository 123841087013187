/**
 * Key of the ZTNA APP to be used in sparky and platform.
 */
export const ZTNA_APP_KEY = 'ztna-connector'

/**
 * ZTNA_APP name.
 * This is used in logs and data-metrics
 */
export const ZTNA_APP = 'ztna_connector'
